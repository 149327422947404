import Observer from '@researchgate/react-intersection-observer';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import GlobalStyle from '../assets/styles/GlobalStyle';
import Button from '../atoms/Button';
import Spacer from '../atoms/Spacer';
import Text from '../atoms/Text';
import { professionistItems } from '../config/cardsContents';
import { NAV_ITEMS, SECTIONS_IDS, URLS } from '../config/constants';
import '../locales/i18n';
import ScrollBottomButton from '../molecules/ScrollBottomButton';
import BecomePartnerSection from '../organisms/BecomePartnerSection/BecomePartnerSection';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import CTABannerSubText from '../organisms/CTABanner/CTABannerSubText';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import ShowcaseCard from '../organisms/ShowcaseCard/ShowcaseCard';
import { navigate } from 'gatsby';
import Layout, { MyContext } from '../layout/LayoutContainer';

class Index extends React.Component {
  state = {
    [SECTIONS_IDS.dealer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.agronomist]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.farmer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.opinions]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.partners]: { visited: false, visibility: 'hidden' },
  };

  handleSectionChange = (event) => {
    const targetId = event && event.target.id;
    this.setState({
      [targetId]: {
        visibility: event.isIntersecting ? 'visible' : 'hidden',
        visited: window.scrollY > 0,
      },
    });
  };

  getSectionClassName = (sectionId) => {
    return `fading-box ${this.state[sectionId].visibility} ${this.state[sectionId].visited && 'visited'}`;
  };

  onLogin = () => navigate(NAV_ITEMS.login.linkTo);
  onPlatformSignup = () => window.open(URLS.signup, '_BLANK');
  onSignup = (persona) => {
    if (persona === 'dealer') {
      navigate(NAV_ITEMS.contactUs.linkTo, {
        state: { persona },
      });
    } else {
      window.open(URLS.signup, '_BLANK');
    }
  };

  render() {
    const { t } = this.props;

    const HeaderCon = (props) => {
      return (
        <MyContext.Consumer>
          {(value) => <Header loginCb={this.onLogin} signupCb={this.onPlatformSignup} {...value} {...props} />}
        </MyContext.Consumer>
      );
    };
    return (
      <Layout loginCb={this.onLogin} signupCb={this.onPlatformSignup} {...this.props}>
        <HeaderCon />

        <CTABanner height={640} bgImage={'home/landing-hero.jpg'} bgPosition={'center'}>
          <div>
            <CTABannerMainText block>{t('app:home:cta:title')}</CTABannerMainText>
            <CTABannerSubText block>
              <Trans i18nKey={'app:home:cta:subtitle'}>
                <Text weight={'bold'} />
                <Text weight={'bold'} />
                <Text weight={'bold'} />
              </Trans>
            </CTABannerSubText>
            <Spacer height={'30px'} />
            <Button big onClick={this.onPlatformSignup}>
              {t('app:signup_btn')}
            </Button>
            <ScrollBottomButton section={'#who-are-you'} />
          </div>
        </CTABanner>

        <section id="who-are-you">
          <div>
            <SectionHeader>
              <SectionHeaderMainText>{t('app:home:who_are_you')}</SectionHeaderMainText>
              <SectionHeaderSubText>
                <Trans i18nKey={'app:home:who_are_you_subtext'}>
                  <Text weight={'bold'} />
                  <Text weight={'bold'} />
                  <Text weight={'bold'} />
                </Trans>
              </SectionHeaderSubText>
            </SectionHeader>
          </div>
        </section>

        {/* Professionists cards */}
        {professionistItems &&
          professionistItems.map((item, i) => (
            <Observer key={i} rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
              <div id={SECTIONS_IDS[item.id]} className={this.getSectionClassName(SECTIONS_IDS[item.id])}>
                <ShowcaseCard
                  reverse={!!(i % 2)}
                  image={`home/${item.image}`}
                  title={t(`app:home:${SECTIONS_IDS[item.id]}`)}
                  description={t(`app:home:${SECTIONS_IDS[item.id]}_desc`)}
                  mainCb={() => this.onSignup(item.id)}
                  mainCbLabel={t('app:signup_btn')}
                  forwardLink={item.linkTo}
                  forwardLinkLabel={t('app:home:learn_more')}
                  bgPosition={item.id === 'dealer' ? '80% 0' : 'center'}
                />
              </div>
            </Observer>
          ))}

        {/* What professionals are saying */}
        {/*<Observer rootMargin="0% 0% -20%" onChange={this.handleSectionChange}>*/}
        {/*  <div id={SECTIONS_IDS.opinions} className={this.getSectionClassName(SECTIONS_IDS.opinions)}>*/}
        {/*    <OpinionsSection />*/}
        {/*  </div>*/}
        {/*</Observer>*/}

        <BecomePartnerSection />

        {/* Our partners */}
        {/*<Observer rootMargin="0% 0% -20%" onChange={this.handleSectionChange}>*/}
        {/*  <div id={SECTIONS_IDS.partners} className={this.getSectionClassName(SECTIONS_IDS.partners)}>*/}
        {/*    <PartnersSection signupCb={this.onSignup} />*/}
        {/*  </div>*/}
        {/*</Observer>*/}

        <Footer />
        <MobileFooter />

        <GlobalStyle />
      </Layout>
    );
  }
}

Index.propTypes = {};

export default withTranslation()(Index);
